<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="needsSignature">
    <div>testing</div>
    <ls-california-compliance-page #caCompliancePage [hidden]="signaturePageType !== SignaturePageType.California" (signatureCompleted)="onSignatureCompleted($event)"></ls-california-compliance-page>
    <ls-illinois-compliance-page #ilCompliancePage [hidden]="signaturePageType !== SignaturePageType.Illinois" (signatureCompleted)="onSignatureCompleted($event)"></ls-illinois-compliance-page>
    <ls-texas-compliance-page #txCompliancePage [hidden]="signaturePageType !== SignaturePageType.Texas" (signatureCompleted)="onSignatureCompleted($event)"></ls-texas-compliance-page>
    <ls-west-virginia-compliance-page #wvCompliancePage [hidden]="signaturePageType !== SignaturePageType.WestVirginia" (signatureCompleted)="onSignatureCompleted($event)"></ls-west-virginia-compliance-page>
    <ls-default-compliance-page #defaultCompliancePage [hidden]="signaturePageType !== SignaturePageType.Default" (signatureCompleted)="onSignatureCompleted($event)"></ls-default-compliance-page>
</div>

<!--<div class="confirmation-text">-->
<!--    Review the Purchase Agreement and sign below that you accept the terms of the Purchase Agreement.-->
<!--</div>-->
