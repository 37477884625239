<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<form class="po-pdf">
    <div class="header">{{headerText}}</div>

    <div class="intro">
        <span class="intro-regular">{{introRegularText1}} {{introRegularText2}}</span>
    </div>

    <div class="parties" [innerHTML]="partiesTextHTML"></div>

    <div class="po-info">
        <table>
            <tr><th *ngFor="let column of columns" [style.width]="column.widthPercent + '%'" [style.textAlign]="'center'">{{column.title}}</th></tr>

            <tr class="bordered-row">
                <td [style.width]="columns[0].widthPercent + '%'" [style.textAlign]="'center'">{{poManager.current.number}}</td>
                <td [style.width]="columns[1].widthPercent + '%'" [style.textAlign]="'center'">Converters</td>
                <td [style.width]="columns[2].widthPercent + '%'" [style.textAlign]="'center'">{{poManager.current.totalNumConverters}}</td>
                <td [style.width]="columns[3].widthPercent + '%'" [style.textAlign]="'center'">{{poManager.current.totalConvertersPrice | currency:'USD':'symbol'}}</td>
            </tr>
        </table>
    </div>

    <div class="payment-type">
        <div>{{paymentTypeHeaderText}}</div>
        <div class="payment-type-checkboxes">
            <p-checkbox *ngFor="let paymentType of paymentTypes" [(ngModel)]="selectedPaymentTypes" [value]="paymentType.value" [label]="paymentType.title"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="paymentTypeChanged()"></p-checkbox>
        </div>
    </div>

    <div *ngIf="selectedPaymentTypes.includes(PurchaseOrderPaymentType.Cash)" class="po-info cash-payment-type">
        <table>
            <tr class="bordered-row">
                <td>{{allowedCashPaymentText}}</td>
                <td>{{allowedCashPayment | currency:'USD':'symbol'}}</td>
            </tr>
            <tr class="bordered-row">
                <td>{{remainingCheckOrWirePaymentText}}</td>
                <td>{{remainingNonCashPayment | currency:'USD':'symbol'}}</td>
            </tr>
        </table>
        <div class="cash-payment-law-text">
            <div>{{cashPaymentLawText1}}</div>
            <div>{{cashPaymentLawText2}}</div>
        </div>
    </div>

    <div class="seller-info">
        <div>{{sellerInfoHeaderText}}</div>

        <p-dropdown [options]="poManager.current.vendor.contacts" [(ngModel)]="selectedContact" [ngModelOptions]="{standalone: true}"
                    optionLabel="fullName" placeholder="Select Contact" (onChange)="onSelectedContactChanged()"></p-dropdown>

        <div class="multiple-fields">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(vehicleLicenseText)}}</span><input type="text" pInputText [(ngModel)]="vehicleLicenseNum" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(stateText)}}</span><input type="text" pInputText [(ngModel)]="vehicleLicenseState" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>

        <div class="multiple-fields">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(idText)}}</span>
                    <input type="text" pInputText [disabled]="true" [(ngModel)]="idNum" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(stateText)}}</span>
                    <input type="text" pInputText [disabled]="true" [(ngModel)]="idState" [ngModelOptions]="{standalone: true}"/>
                </div>
            </div>
        </div>
    </div>

    <div class="signature-section">

        <div>{{signaturePrelude}}</div>
        <div class="signature-prelude-warning">{{signaturePreludeWarning}}</div>

        <div class="multi-signature-line tall signature">
            <div class="text">{{removeUnderscores(signatureText)}}</div>
            <div class="signature-auto-expand"><ls-capture-signature #signature></ls-capture-signature></div>
        </div>

        <div class="multiple-fields name-and-date">
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(printText)}}</span><input type="text" pInputText [(ngModel)]="name" [ngModelOptions]="{standalone: true}" class="name"/>
                </div>
            </div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(dateText)}}</span>
                    <p-calendar [(ngModel)]="date" [ngModelOptions]="{standalone: true}" [styleClass]="ThemeManager.theme" appendTo="body" inputStyleClass="calendar-input-style"></p-calendar>
                </div>
            </div>
        </div>
    </div>

    <div class="buttons">
        <div class="buttons-left">
            <p-button severity="secondary" (click)="clearSignatures()">Clear Signatures</p-button>
        </div>
        <div class="buttons-right">
            <p-button severity="secondary" (click)="onCancel()">Cancel</p-button>
            <p-button (click)="onSave()" [disabled]="signature.isEmpty || name.length === 0 || date == null || vehicleLicenseNum.length === 0 ||
                vehicleLicenseState.length === 0 || idNum.length === 0 || idState.length === 0 || selectedPaymentTypes.length === 0 ||
                      (remainingNonCashPayment> 0 && !selectedPaymentTypes.includes(PurchaseOrderPaymentType.Check) &&
                !selectedPaymentTypes.includes(PurchaseOrderPaymentType.WireTransfer))" >Confirm
            </p-button>
        </div>
    </div>
</form>
